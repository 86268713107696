
<template>
<div>
  <div class="c-buttons-row--container">
    <div class="c-buttons-row">
      <div class="main-menu"><router-link to="/records">Журнал счетов</router-link></div>
      <div class="main-menu"><router-link to="/about">О проекте</router-link></div>
      <div class="main-menu"><router-link to="/login">Вход</router-link></div>
    </div>
  </div>
  <div class="container">
    <div class="login-menu">
      <h1><b>О проекте</b></h1>
      <p>Рекомендуемый браузер - Google Chrome.</p>
      <p>Это WEB-конфигуратор, он только для профессионалов и требует предварительной регистрации.</p>
      <p>Вы можете получить логин и пароль и документацию на WEB-конфигуратор у своего менеджера.</p>
      <p style="color:red">Не сообщайте свой логин и пароль посторонним, чтобы избежать потери данных.</p>
      <p style="color:red">Не сохраняйте свой логин и пароль на компьютерах, к которым имеют доступ посторонние.</p>
      <p style="color:red">Если Ваш логин и пароль скомпроментированы, немедленно сообщите об этом:</p>
      <p style="color:red">+7(915)435-4956 (Mobile, WhatsApp).</p>
      <br />
      <h1><b>Загрузка базы данных</b></h1>
      <p>Применяется, если Вы ранее работали с Desktop-конфигуратором.</p>
      <p>Порядок загрузки:</p>
      <p>1. В Desktop-конфигураторе выберите: "Настройки" / "Выгрузить базу данных в файл".</p>
      <p>... В папке "Документы" будет создан файл с базой данных.</p>
      <p>2. Отправьте этот файл своему менеджеру.</p>
      <p>3. Получите подтверждение загрузки и проверьте журнал счетов.</p>
      <p>... Вы перешли на WEB-конфигуратор, далее работаете только с ним.</p>
      <br />
      <p>После перехода всех пользователей на WEB-конфигуратор, Desktop-конфигуратор будет отключен.</p>

      <div v-if="user_name=='dyakin' || user_name=='dd'">
        <p><button @click="download_data_click">Загрузить</button></p>
        <p><button @click="set_id_click">Заполнить ID</button></p>
      </div>

    </div>
  </div>
</div>
</template>

<script>

import {mapActions, mapState} from 'vuex';

export default {
  name: "about",
  components: {
  },
  data() {
    return {
    };
  },
  props: {
  },
  computed: {
    ...mapState([
      "user_name",
    ])
  },
  methods: {
    ...mapActions([
      "DOWNLOAD_DATA",
      "SET_ID",
    ]),
    download_data_click(){
      this.DOWNLOAD_DATA();
    },
    set_id_click(){
      this.SET_ID();
    },
  },
  mounted() {
  },
  updated(){
  },
  beforeDestroy() {
  },
};
</script>

<style type="text/css">


</style>
